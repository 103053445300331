.section-white {
	background:#191924;
	padding: 70px 0;
}

.team-item{
	background: #583965;
	text-align:center;
	margin:20px 0;
	padding:50px 20px 40px 20px;
	border-radius: 8px 8px;
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}

.team-item:hover{
	background: #191924;
	color: #fff44f;
}

.team-item h3{
	margin:20px 0 1px 0!important;
	color:#fff44f;
	font-size:18px;
	font-weight: 700;
	text-transform:uppercase;
	transition: all .25s ease-in-out;
	-moz-transition: all .25s ease-in-out;
	-webkit-transition: all .25s ease-in-out;
}
.section-title{
	color:#fff44f;
}

.section-subtitle {
	white-space: pre-line;
	color:#fff44f;

 }

.team-item:hover h3{
	color:#fff44f;
}

.team-info {
	display: inline;
	margin-bottom:0;
}

.team-info p{
	color:#fff44f;
}
.team-info::after {
    background: #008aff;
    background: -webkit-linear-gradient(135deg, #6B02FF 0%, #985BEF 100%);
    background: -o-linear-gradient(bottom right, #6B02FF, #985BEF);
    background: -moz-linear-gradient(bottom right, #008aff, #1ad2fd);
    background: linear-gradient(135deg, #6B02FF 0%, #985BEF 100%);
    display: inline-block;
    vertical-align: middle;
    content: "";
    width: 50px;
    height: 3px; 
}

.team-img{
	max-width: 140px;
	padding: 6px 6px;
    background-color: #fff;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
}

ul.team-icon {
	margin-top: 25px;
}

ul.team-icon li{
	display: inline-block;
	margin:0 3px;
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}

ul.team-icon li a{
	margin: 0;
	display: inline-block;
	padding: 8px 8px;
	width: 34px;
	height: 34px;
	font-size: 18px;
	line-height: 18px;
	background-color: #fff;
	border-radius: 50%;
	color:#fff;
}

ul.team-icon li a.twitter{
	background-color: #4099ff;
}

ul.team-icon li a.pinterest{
	background-color: #C92228;
}

ul.team-icon li a.facebook{
	background-color: #3b5998;
}

ul.team-icon li a.dribble{
	background-color: #ea4c89;
}

.team-icon li a:hover{
	color:#dedede;
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}